:root {
  --width: 300px;
  --height: 200px;
  --x-speed: 13s;
  --y-speed: 7s;
  --transition-speed: 2.2s;
}
span {
  display: inline-block;
  position: absolute;
}

.el {
  width: var(--width);
  height: 500px;
}

.x {
  animation: x var(--x-speed) linear infinite alternate;
}
.y {
  animation: y var(--y-speed) linear infinite alternate;
}

@keyframes x {
  100% {
    transform: translateX(calc(100vw - var(--width)));
  }
}
@keyframes y {
  100% {
    transform: translateY(calc(100vh - var(--height)));
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#smiley {
  position: absolute;
  height: 20%;
  top: 12%;
  left: 2%;
}
.pic {
  height: 100%;
  width: auto;
}
.pics {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 50vh;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .pics {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 50vh;
  }
}
